import DataTable from "react-data-table-component";
import 'react-confirm-alert/src/react-confirm-alert.css';
import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../fornecedores-rebate/fornecedores-rebate.css";
import fornecedoresRebatePDF from "../../report/report.fornecedores.rebate/report.fornecedores.rebate.jsx";
import ExibeMensagem from "../../components/mensagens/mensagens.js";
import { dadosLogin } from "../../zustand/zuztand.js"

function FornecedoresRebate() {
    const navigate = useNavigate();
    const [buscaCnpj, setBuscaCnpj] = useState("");
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dadosForn, setDadosForn] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);

    const [regiao_forn, setRegiao_forn] = useState("");
    const [clas_fornecedor, setClas_fornecedor] = useState("");
    const [buscaFornecedor, setBuscaFornecedor] = useState("");
    const [buscaProduto, setBuscaProduto] = useState("");
    const [grupo_forn, setGrupo_forn] = useState("");
    const { ifLogado, usuario, nivel_user } = dadosLogin();
    const [id_fornecedor, setId_fornecedor] = useState(0);
    const [nome_fornecedor, setNome_fornecedor] = useState("");
    const [taxa_rebate, setTaxa_rebate] = useState("");
    const [sucesso, setSucesso] = useState(false);

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_fornecedor).slice(-4), //para colocar zero a esquerda
            sortable: true,
            width: "9%",
        },
        {
            name: 'Nome Fornecedor',
            selector: row => row.nome_fornecedor,
            sortable: true,
            width: "35%",
            compact: true
        },
        {
            name: 'Grupo',
            selector: row => row.grupo_forn,
            sortable: true,
            width: "10%",
            compact: true
        },
        {
            name: 'CNPJ',
            selector: row => row.cnpj_fornecedor,
            sortable: false,
            width: "15%",
        },
        {
            name: 'Taxa rebate %',
            selector: row => row.taxa_rebate,
            width: "22%",
            sortable: true,
            center: true
        },
        {
            name: 'Editar',
            cell: (row) => <>
                <button onClick={() => EditarTaxaRebate(row.id_fornecedor, row.nome_fornecedor, row.taxa_rebate)} className="btn btn-primary ms-3" type="button" data-bs-toggle="modal" data-bs-target="#ModalRebate"><i className="bi bi-pencil-square"></i></button>
            </>,
            center: true,
            width: "8%",
        }
    ];

    function AltTaxaRebate() {
        const param2 = {
            taxa_rebate,
        }

        api.put("/fornecedores_edit_taxa/" + id_fornecedor, param2)
            .then((resp) => {
                if (resp.status === 200) {
                    ListarFornecedor(1, regiao_forn, 1, 1000);
                    MostraMensagem();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                alert("Erro ao editar dados");
            });
    }

    function MostraMensagem() {
        setSucesso(true)
        function esperaAtualizar() {
            setSucesso(false)
        }
        setTimeout(esperaAtualizar, 2000); // Executará esperaAtualizar após meio segundo     
    }

    function EditarTaxaRebate(id, nome_fornecedor, taxa_rebate) { //pega os ados antes de abrir o modal de edicao registro
        localStorage.setItem('id_openFornecedor', id);
        localStorage.setItem('nome_openFornecedor', nome_fornecedor);
        localStorage.setItem('taxa_openrebate', taxa_rebate);

        setId_fornecedor(id);
        setNome_fornecedor(nome_fornecedor);
        setTaxa_rebate(taxa_rebate);
    }

    function EditarFornecedorSalvo() { //para abrir o ultimo visualizado
        setId_fornecedor(localStorage.getItem('id_openFornecedor'));
        setNome_fornecedor(localStorage.getItem('nome_openFornecedor'));
        setTaxa_rebate(localStorage.getItem('taxa_openrebate'));
    }

    function ListarFornecedor(id_usuario, regiao_forn, pagina, qtd_reg_pagina) {
        var qtd_reg_pagina = 500 //para trazer todos os dados
        api.get("/fornecedores_listar", { params: { id_usuario, regiao_forn, pagina, qtd_reg_pagina } })
            .then((resp) => {
                let dadosTaxa = []
                resp.data.dados.map((item) => {
                    if (item.taxa_rebate > 0) {
                        dadosTaxa.push(item);
                    }
                })
                //console.log(dadosTaxa)
                setTotalRegistros(dadosTaxa.length);
                setDadosForn(dadosTaxa);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFornecedorGrupo(grupo_forn) {//lista os fornecedores pelo grupo A, B ou C.

        api.get("/fornecedores_lista_grupo", { params: { grupo_forn } })
            .then((resp) => {
                //console.log(resp.data.length)
                let dadosTaxa = []
                resp.data.map((item) => {
                    if (item.taxa_rebate > 0) {
                        dadosTaxa.push(item); //pegando somente os que tem texa maior que 0
                    }
                })
                setDadosForn(dadosTaxa);
                setTotalRegistros(dadosTaxa.length)
            })
            .catch((err) => {
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorNome() {
        api.get("/fornecedores_busca/", { params: { buscaFornecedor } })
            .then((resp) => {
                setDadosForn(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorCnpj() {
        api.get("/fornecedores_busca_cnpj/", { params: { buscaCnpj } })
            .then((resp) => {
                setDadosForn(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFornecedorTodos() {
        ListarFornecedor(localStorage.getItem("id_usuario"), "", 1, 1000);// btn mostrar todos
    }

    function ChangeGrupo(e) {
        setGrupo_forn("");
        setGrupo_forn(e.target.value);
        ListarFornecedorGrupo(e.target.value);
    }

    function ListarFornecedorId() {

        let id_fornecedor = localStorage.getItem("id_fornecedor")

        api.get("/fornecedores_id/" + id_fornecedor)
            .then((resp) => {
                let arrayNovo = []
                arrayNovo.push(
                    {
                        id_fornecedor: resp.data.id_fornecedor,
                        nome_fornecedor: resp.data.nome_fornecedor,
                        cnpj_fornecedor: resp.data.cnpj_fornecedor,
                        tel_fornecedor: resp.data.tel_fornecedor,
                        grupo_forn: resp.data.grupo_forn,
                        taxa_rebate: resp.data.taxa_rebate
                    }
                )
                setDadosForn(arrayNovo);
                //setTotalRegistros(resp.data.length);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }
/*
    useEffect(() => {
        if (buscaFornecedor === "") {
            ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, 1, qtd_reg_pagina);
        } else {
            PesquisarFornecedorNome();
        }
    }, [buscaFornecedor]);

    useEffect(() => {
        if (buscaCnpj === "") {
        } else {
            PesquisarFornecedorCnpj();
        }
    }, [buscaCnpj]);
*/

    useEffect(() => {
        localStorage.setItem("id_usuario", 1)
       // ListarFornecedor(localStorage.getItem("id_usuario"), clas_fornecedor, 1, qtd_reg_pagina);
       ListarFornecedorId();
    }, []);

    return <>
        {
            ifLogado != "notLogad" ? <>
                <div className="container-fluid ms-4">
                    <div className="row flex-nowrapl me-4">
                        <div className="col py-3 me-3">
                            <div className="input-group justify-content-end">
                                <input onChange={(e) => setBuscaFornecedor(e.target.value)} type="text" className="form-control buscainput-fran" id="pesq" placeholder="Pesquisar Fornecedor (todos)" value={buscaFornecedor} />
                                <button onClick={ListarFornecedorTodos} className="btn btn-primary mb-2" type="button" >Mostrar Todos</button>
                                <button onClick={EditarFornecedorSalvo} className="btn btn-primary mb-2 bi bi-arrow-left-square ms-2" type="button" data-bs-toggle="modal" data-bs-target="#ModalRebate"></button>
                                <div>
                                    <Link to="/fornecedores" className="btn btn-success p-2 ms-3 btn-sm" >Voltar </Link>
                                </div>
                            </div>

                            <div className="bg-white p-4 rounded-4 border ">
                                <div className="d-flex justify-content-between  mb-3  titulo-tabela">
                                    <div className="d-flex">
                                        <small className="text-secundary fw-bold mt-2 ">Grupo</small>
                                        <div className="form-control div_grupo_forn ms-2">
                                            <select name="reg" id="reg" onChange={ChangeGrupo}>
                                                <option value="">Sel.</option>
                                                <option value="A">Grupo A</option>
                                                <option value="B">Grupo B</option>
                                                <option value="C">Grupo C</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="d-flex" >
                                        <small className="text-secundary fw-bold mt-2 me-1">CNPJ</small>
                                        <input type="text" className="form-control div_cnpj_forn ms-2" onChange={(e) => setBuscaCnpj(e.target.value)} placeholder="99.999.999/9999-99" />
                                    </div>
                                    <div className="align-items-end me-4">
                                        <button onClick={(e) => fornecedoresRebatePDF(dadosForn)} className="btn btn-danger ms-2"><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                                    </div>
                                </div>

                                <DataTable className="tabela-fornecedores" columns={columns}
                                    data={dadosForn}
                                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                                />
                                <div className="d-flex justify-content-end mt-2 me-4">
                                    <small >Total de registros:
                                        {total_registros}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></> : navigate('/login')
        }

        {/*----------------------------------------------- Modal rebate Inicio -----------------------------------------*/}
        <div className="modal fade" id="ModalRebate" tabindex="-1" aria-labelledby="ModalRebate" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="ModalRebate">Editar taxa de rebate Fornecedor</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="col-12 bg-white rounded-4" >
                            <div className="container-fluid h-100  sidebar">
                                <div className="row  ">
                                    <div className="col-2 div-cod-id">
                                        <label htmlFor="InpuCod" className="text-secondary">Código</label>
                                        <input type="text" className="form-control " id="Inputidf" value={id_fornecedor} />
                                    </div>

                                    <div className="col-9 ">
                                        <label htmlFor="InputFor" className="text-secondary">Fornecedor:</label>
                                        <input type="text" className="form-control " id="InputNomef" value={nome_fornecedor} />
                                    </div>

                                    <div className="col-2 ">
                                        <label htmlFor="InpuTaxa" className="text-secondary">Taxa rebate %</label>
                                        <input type="text" className="form-control div-cod-id text-end ms-3" id="Inputtx" onChange={(e) => setTaxa_rebate(e.target.value)} value={taxa_rebate} />
                                    </div>
                                    {
                                        sucesso ?
                                            <ExibeMensagem msn=" Taxa de rebate alterada com sucesso! " /> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button onClick={AltTaxaRebate} className="btn btn-primary">Salvar</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Sair</button>
                    </div>
                </div>
            </div>
        </div>
        {/*----------------------------------------------- Modal rebate Fim -----------------------------------------*/}
    </>
}
export default FornecedoresRebate;
